import React, { Fragment } from "react";
import {
  DialogWrapper,
  Input,
  Button,
  Selector,
  TextArea,
  Title,
} from "./DialogWidgets";
import * as Icon from "./Icon";
import * as Ant from "antd";

class PrintNoticeDialog extends React.Component {
  render() {
    let { closeDialog, show } = this.props;

    return (
      <Ant.Modal
        visible={show}
        title={<Title>感官文化印刷印刷須知</Title>}
        footer={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button css="margin: 10px;" onClick={() => closeDialog()}>
              確定
            </Button>
          </div>
        }
        closeIcon={
          <Icon.Close
            style={{ position: "absolute", right: 20, top: 20 }}
            size={20}
          />
        }
        onCancel={closeDialog}
        style={{
          textAlign: "center",
        }}
        width="80%"
      >
        <DialogWrapper>
          <div
            className="content"
            style={{ textAlign: "left", whiteSpace: "pre-wrap" }}
          >
            <p>{`感官文化印刷有限公司 (以下簡稱「本公司」) 提供感官文化印刷 Sensations Print 電子商務網站服務 (以下簡稱「本網站」)。
以此書面告知可能會發生之印刷狀況，當會員開始使用本網站服務時，
即表示已閱讀、瞭解並同意接受本網站印前須知之所有內容，並完全接受本網站服務現有與未來衍生的服務項目及內容。`}</p>

            <h4>一、檔案交付注意事項</h4>
            <p>{`若因下列原因而延誤交貨時間，後果由會員自行負責，本公司不負任何延後之賠償之責任。為維護您的權益，請務必詳讀本頁印刷須知所有條文。`}</p>

            <p>{`● 印刷品下單前相關注意事項：
(1) 請會員務必了解每種印製方式對您印件的適用性再行下單。
(2) 稿件若於印刷後發現檔案製作因素而無法加工（ 如摺紙、上光、裝訂等 ）等，相關瑕疵，客戶需自行確認並負責，本公司無幫助確認之義務。
(3) 印件製作時，請依據完稿說明預留出血線範圍，避免裁切時裁切到文字與相關內容。
(4) 印刷品若有後續加工如摺紙、上光、裝訂、軋型、打圓孔、燙金、流水號、打圓角等，每項加工皆會有3%-5%的耗損為正常情況，請自行預估並增加印製數量。`}</p>

            <p>{`● 檔案製作注意事項：
(1) 所有圖檔皆由客戶所交付檔案直接印刷，本公司不提供任何圖檔修改與設計。
      本公司不提供修改客戶送印檔案之服務。如：修改字體 / 文字內容 / 檔案顏色 / 刀模範圍 / 檔案大小...等修改。
(2) 請會員務必參照各類產品之完稿注意事項，送印檔案完稿需由會員自行完成。
      本公司無協助檢查所有稿件正確性之責任！下單前請確認！
(3) 如檔案有問題導致無法印刷，本公司一率退回給予會員修正後方才印刷。若檔案不符合基本規定，一律以退件處理，若不願意被退件執意印刷，恕本公司不負印刷責任。
(4) 審稿階段僅提供 : 確認稿件完稿尺寸 / 檔案規格是否符合印製要求等服務。
      若因檔案顏色設定 / 加工圖層設計 / 物件及文字轉外框 / 特效點陣化 / 刀模移位等問題，而導致印製成品有誤，客戶需自行負責。
(5) 上傳的檔案有問題或下單資訊不明，皆退回訂單。
(6) PDF轉存時請選取「列印品質」，並「不要」將「保留Illustrator編輯能力」打勾。
(7) 數位印刷者，因數位印刷的印刷條件限制，印刷大面積灰階色彩、淡色時會因網點分布關係容易產生條紋現象，可接受後再進行正式印製。
(8) 檔案製作儲存時，僅需提供需要發印之內容交付本公司，請不要多存不必要的檔案或資料，以免工作人員拉錯檔案造成錯印。 
(9) 檔案一經發印，若需取消訂單或重傳檔案請務必來電確認印製狀況，以免重複印刷。若訂單狀態已開始印製，恕無法中途終止訂單或要求訂單退款。
(10) 上傳檔案請避免四色黑之稿件（尤其為文字部分），以免造成對位不準之狀況。發印前請會員自行檢查，
        若在印製上機時發現因四色黑導致對位不準而無法究受之會員，我方亦不負任何相關責任。
(11) 若因此需要更換檔案、重新開版印製等狀況，我方需酌收衍生相關印刷費用。`}</p>

            <p>{`● 檔案交付注意事項：	
(1) 上傳送印檔案請登入本公司網站會員，於訂單頁面在品項『上傳檔案區』直接上傳檔案，提醒您，已付款訂單商品在未上傳檔案前不會進審稿流程，請務必確認已上傳送印檔案。
(2) 檔案交付後需要審稿時間確認檔案，確認無誤後方得印刷。雙方確認檔案時間不算在印刷工作天內，以訂單審稿合格隔天開始計算工作天，再請會員注意交貨時間。
(3) 本公司對印件內容不負法律責任，委印會員需自行查核印件內容是否有觸法，如著作權等。
(4) 會員在確認傳檔、送檔發印，即代表瞭解本公司所有規範項目，並同意以此作為日後責任歸屬之判定。
(5) 印件若有交貨時間壓力時請勿直接下單，需加收急件費補收款項，加收金額依實際工作狀況而定。（建議先來電告知協商交貨時間後再下單。）
(6) 檔案一經發印，若需取消訂單或重傳檔案請務必來電確認印製狀況，以免重複印刷。若訂單狀態已開始印製，恕無法中途終止訂單或要求訂單退款。
(7) 檔案校稿與發印皆採用本網站訂單系統為執行憑據。訂單送印檔案請勿以Email方式傳檔，以免漏信狀況漏單。
(8) 本網站印製方式者恕不提供免費打樣，若有打樣需求需另外報價並收費。
(9) 若修改檔案而需新開版印製等狀況，我方需酌收衍生相關印刷費用。
(10) 會員提供之檔案或稿件，經確認打樣印製後所產生之稿件正確性問題，本公司概不負責。再煩請客戶請仔細校對內容文字圖片。
(11) 請會員客戶提供 Adobe CS6完稿檔案：檔案格式以 AI、PSD、INDESIGN 可相容且可讀取之檔案格式。若非上述軟體，請將檔案轉存成 PDF 或 EPS 交換格式。
(12) 本公司審稿軟體皆以上述版本開啟，為避免跨版本之不可預期之問題，請客戶軟體版本升級與本公司同步。
`}</p>

            <h4>二、報價與付款</h4>
            <p>{`(1) 本網站下單後尚未付款之訂單僅保留7天，請於時間內完成訂單付款。
(2) 訂單下單後保留7天，若超過7個工作天未付款訂單經本系統自動作廢，則不保留原訂單優惠及權益，請會員重新下單。
(3) 本網站訂單如經會員下單付款後製作，視同訂購契約，中途如有更改或因故停止製作，本公司得依已製作部份收取製作費用。
(4) 若因本網站訂單內容有異動如：原物料缺貨 / 調整印製方式 / 系統計算錯誤等問題，本公司保留最後接單與否或調整訂單金額之權利。
(5) 因印刷品屬於客製化商品，故本網站採先付款後製作方式，本網站訂單恕不接受先審稿或印製再行付款。
(6) 人工報價本公司報價人員如報價錯誤 ( 如: 稅金 / 數量 / 折扣等問題 )，本公司保留最後接單與否之權利。
(7) 自行取貨者免收運費外，若由自取改為其他配送需求如：郵局、貨運、快遞等，皆依據內容物收取補收款運費。若為分批交貨者，皆需依實際情況補收運費。
(8) 本公司按照相關印刷流程進行出貨，若會員有特殊要求需要在下單付款前告知，本公司將視出貨包裝複雜度酌收相關包材補收款項。
`}</p>

            <h4>三、商品瑕疵判定及退換貨說明</h4>
            <p>{`印刷品屬於客製化商品無法退貨，不適用消費者保護法第十九條七日猶豫期間規定。雙方在簽署報價單並製作後即是同意本網站所載明之須知。
詳細訂單商品正常瑕疵範圍認定請參考：『常見問題FAQ > 印刷須知 > 瑕疵判別及正常範圍說明』。`}</p>

            <p>{`● 色差及誤差認定：
每個人對色彩也有不同的主觀反應。故有下述狀況時，將由本公司評估印刷品狀況，並通知會員是否給予退換貨。
(1) 採數位印刷印製卡片類者（如名片、喜帖等），紙張條數若大於35條以上者，在印刷時容易出現偏移狀況，
      若對位置非常講究者，建議改採開版印製。若執意以數位印刷印製者，無法以此理由進行退換貨。
(2) 因個人螢幕顯示色彩不同，實品與照片會有些微色差，故無法依此作為退換貨理由，若無法接受者，請勿下單。
(3) 所有紙張與印製材質皆有不同特性及成色表現，請勿以數位印刷稿件或電腦手機螢幕顏色，作為印刷顏色校色樣本，若無法接受者，請勿下單。
(4) 依照所選印刷材質與印刷方式，成品顏色誤差於10%-20%之間皆屬正常範圍 (以校色系統為依據)，會員不得以色差相關理由要求退貨重印或要求賠償。
(5) 相同檔案不同次或不同時間下單印刷，因每次印製機器數值或每批材料會有誤差，使得成品色彩會有差距，色差於10%左右乃屬正常現象。
      若追求印件顏色一致，舊檔追加印製前，請附上之前本公司印製的正確色樣品校色，以免色差過大。
(6) 每批次紙張或商品原料，其色澤及紋理難免會有些微差異 (如:偏黃、偏白、條紋方向、厚度等）以致於商品成色每批略有差異，恕無法以此原因作為退貨理由，敬請見諒。
(7) 請勿使用自訂特別色與其他色盤的特別色，若有特別色使用，請自行轉換為CMYK值的填色，以避免因為色彩轉換產生印製成品的認知差異。
(8) 印刷會因為不同紙張、空氣溼度、機器狀況、版調曲線、網點擴張、油墨廠牌 等因素，而造成印刷結果不一，在不同的時間空間與光線下觀看感受也會不同，恕無法以此原因作為退貨理由。
(9) 各家印刷廠使用的油墨、紙張不同，非本公司承印物件，恕無法做為對色樣本。螢幕或噴墨列印稿的顏色，無法做為印刷顏色樣本。
(10) 會員提供檔案有誤，如未出血、重要圖文太靠邊等而導致裁切露白邊獲裁切到重要圖文。`}</p>

            <p>{`● 除上述原因外，若有以下情事者恕本公司無法受理退換貨，還請見諒：
(1) 會員若對印刷成品有相關要求，若採人工報價者需在報價階段提出，並由我方載明於報價單之中，客戶經簽署後即同意以此做法進行，
(2) 交貨後若提出相關未載明於報價單上、Mail、Line、傳真等系統之內容及驗收品檢條件，或本公司視為正常印刷品情況但會員無法究受者，恕不受理。
(3) 印刷解析度要求為300dpi以上，若會員檔案未使用足夠解析度的圖片而導致印刷後圖片模糊，恕不受理。
(4) 會員提供檔案有誤，如未出血、重要圖文太靠邊，而導致裁切露白邊或裁切到重要圖文等問題，恕不受理。`}</p>

            <p>{`● 退換貨說明
(1) 現場取貨者請當場點清數量並確認印刷品狀況，離開本公司後，恕不相關責任；若商品為寄送者，收到商品後若有相關問題，請於3日內聯繫，逾期恕不受理。
(2) 退回貨品必須是全新狀態且包裝完整 (保持印刷品數量、附件、包裝、紙箱及所有附隨文件或資料之完整性)，否則恕不接受退換貨。
(3) 除非有嚴重瑕疵（印刷過程一定會產生的摺痕、瑕疵等由我方判斷屬合理之印刷或出現之現象等則不在此限)，否則不予換貨。
(4) 詳細訂單商品退換貨辦法請參考：『常見問題FAQ > 印刷須知 > 退換貨及退款流程說明』
(5) 本網站零售商品、非客製化印刷商品則依據消費者保護法之規定，消費者得於收到商品或接受服務後七天內（以系統訂單完成的隔天開始算七天，包含例假日），
      以退回商品或書面通知方式解除契約。七天鑑賞期係指猶豫期而非試用期，商品須在完整且可還原狀態下方能進行退貨。`}</p>

            <p>{`──  感官文化印刷有限公司`}</p>
          </div>
        </DialogWrapper>
      </Ant.Modal>
    );
  }
}

export default PrintNoticeDialog;

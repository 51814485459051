function isPersonCertificatedCode(string) {
  //台灣自然人憑證(2碼英文字母加上14碼數字)
  var regexp = /^[a-zA-Z]{2}[0-9]{14}$/;
  return regexp.test(string);
}

function isMobileVehicleCode(string) {
  // 手機載具(斜線(/)加上7碼數字或大寫字母)
  var regexp = /^\/[0-9A-Z\+\-\.]{7}$/;
  return regexp.test(string);
}

function isCompanyId(inputTaxID) {
  // 處理無效輸入
  if (inputTaxID == null) return false;

  // 確保輸入值為字串
  const taxID = String(inputTaxID).trim();

  // 統一編號應為 8 位數字
  if (!/^\d{8}$/.test(taxID)) {
    return false;
  }

  const weights = [1, 2, 1, 2, 1, 2, 4, 1];
  let sum = 0;

  try {
    for (let i = 0; i < 8; i++) {
      let product = parseInt(taxID[i], 10) * weights[i];
      sum += Math.floor(product / 10) + (product % 10);
    }

    // 第 7 位數字不是 7，檢查是否為 5 的倍數
    if (taxID[6] !== "7") {
      return sum % 5 === 0;
    }

    // 第 7 位數字是 7，最後第二位數取 0 或 1，檢查是否為 5 的倍數
    return sum % 5 === 0 || (sum + 1) % 5 === 0;
  } catch (error) {
    console.error("Error validating company ID:", error);
    return false;
  }
}

function isLoveCode(string) {
  //愛心捐贈碼(由3至7位數字組成)
  var regexp = /^[0-9]{3,7}$/;
  return regexp.test(string);
}

function isNotEmpty(value) {
  if (typeof value === "string") {
    return value.trim();
  }

  return value === 0 || value;
}

function isEmail(email) {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function isMobileNumber(phone) {
  let re = /^([0|+[0-9]{1,5})?([7-9][0-9]{8})$/;
  return re.test(phone);
}

function isNumeric(value) {
  let re = /^[0-9]*$/;
  return re.test(value);
}

function isExceedStringMaxLength(value) {
  let re = { test: value => value.length >= 128 };
  return re.test(value);
}

function isValidZipCode(value) {
  let re = /^[0-9]{3}$/;
  return re.test(value);
}

const formatValidator = {
  isPersonCertificatedCode,
  isMobileVehicleCode,
  isCompanyId,
  isLoveCode,
  isNotEmpty,
  isEmail,
  isMobileNumber,
  isNumeric,
  isExceedStringMaxLength,
  isValidZipCode,
};

export default formatValidator;

import React, { useState } from "react";
import { Radio } from "antd";
import styled from "styled-components";

import { PAYMENT_TYPES } from "../../dictionary";
const constants = require("../../constants");

export default function PaymentMethods({ paymentValue, setParams }) {
  const [type, setType] = useState(paymentValue);

  // 濾掉不需要的付款方式
  // 不要有月結付款跟訂閱制
  const options = PAYMENT_TYPES.filter(
    item => item.value !== "monthly" && item.value !== "period"
  );

  const handleRadioOnChange = value => {
    setType(value);
    const mapping = {
      atm: { payment_type: "payuni", payment_subtype: value },
      credit: { payment_type: "payuni", payment_subtype: value },
      cvs: { payment_type: "payuni", payment_subtype: value },
      line_pay: { payment_type: "payuni", payment_subtype: value },
      credits: { payment_type: value, payment_subtype: "default" },
      monthly: { payment_type: value, payment_subtype: "default" },
      offline: { payment_type: value, payment_subtype: "default" },
      period: { payment_type: "period", payment_subtype: "default" },
    };
    setParams(mapping[value]);
  };

  const renderIntro = type => {
    const item = options.find(item => item.value === type);
    return <div dangerouslySetInnerHTML={{ __html: item.intro }} />;
  };
  return (
    <Radio.Group
      onChange={e => handleRadioOnChange(e.target.value)}
      value={type}
      style={{ width: "100%" }}
    >
      <ul style={{ listStyle: "none" }}>
        {options.map((item, idx) => (
          <PaymentMethodItem
            label={item.label}
            value={item.value}
            key={item.label}
            intro={item.intro}
            selected={type === item.value}
          />
        ))}
      </ul>
      <Tips>{type && renderIntro(type)}</Tips>
    </Radio.Group>
  );
}

const PaymentMethodItem = ({ label, value, selected }) => {
  return (
    <StyledLi>
      <Radio value={value}>
        {selected ? (
          <img src="/images/circle-checked.svg" alt="circle-checked" />
        ) : (
          <img src="/images/circle-outlined.svg" alt="circle-outlined" />
        )}
        {label}
      </Radio>
    </StyledLi>
  );
};

const Tips = styled.p`
  color: #5b616e;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.014px;
  margin-top: 32px;
  margin-bottom: 0;

  .emphasized {
    color: #1b1c1f;
  }

  .notice {
    margin: 0;
    padding-top: 16px;
    color: ${constants.colors.highlight};
  }

  @media only screen and (max-width: ${constants.breakpoints.lg}px) {
    margin-bottom: 0;
  }
`;

const StyledLi = styled.li`
  padding: 8px 0;
  height: 40px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #25272c;
  margin-bottom: 16x;

  .ant-radio-wrapper {
    border-radius: 50%;
    width: 100%;
    font-size: ${constants.fontSize.sm}px;
  }

  .ant-radio {
    display: none;

    & + span {
      padding: 0;
      display: flex;
      gap: 8px;
      width: 100%;
    }
  }
`;
